import gql from 'graphql-tag';
import { defineComponent, ref } from 'vue';

const CREATE_CLIENTS_CSV_REQUEST = gql`
  mutation createClientsCsvRequest {
    createClientsCsvRequest
  }
`;

const CREATE_SITE_STATUS_CSV_REQUEST = gql`
  mutation createSiteStatusCsvRequest($emails: [String]) {
    createSiteStatusCsvRequest(emails: $emails)
  }
`;

export default defineComponent({
    name: 'CsvCreator',

    setup() {
        const showDialog = ref(false);
        const showEmailInput = ref(false);
        const emailsText = ref('');

        return {
            showDialog,
            showEmailInput,
            emailsText
        };
    },

    methods: {
        async sendLink() {
            try {
                this.$q.notify('Sending email');
                await this.$apollo.mutate({
                    mutation: CREATE_CLIENTS_CSV_REQUEST
                });
                this.$q.notify('Email sent');
            } catch (error) {
                console.error('Error creating csv request:', error);
                this.$q.notify({
                    color: 'negative',
                    message: 'Failed to send email request'
                });
            }
        },

        showSiteStatusDialog() {
            this.showDialog = true;
            this.showEmailInput = false;
            this.emailsText = '';
        },

        resetDialog() {
            this.showDialog = false;
            this.showEmailInput = false;
            this.emailsText = '';
        },

        async handleAllClients() {
            try {
                this.$q.notify('Sending site status export email');
                await this.$apollo.mutate({
                    mutation: CREATE_SITE_STATUS_CSV_REQUEST,
                    variables: {
                        emails: null
                    }
                });
                this.$q.notify('Email sent');
                this.resetDialog();
            } catch (error) {
                console.error('Error creating site status csv request:', error);
                this.$q.notify({
                    color: 'negative',
                    message: 'Failed to send site status export request'
                });
            }
        },

        async handleSelectedEmails() {
            try {
                // Process emails: split by newline and trim each email
                const emails = this.emailsText
                    .split('\n')
                    .map(email => email.trim())
                    .filter(email => email.length > 0);

                if (emails.length === 0) {
                    this.$q.notify({
                        color: 'warning',
                        message: 'Please enter at least one valid email address'
                    });
                    return;
                }

                this.$q.notify('Sending filtered site status export email');
                await this.$apollo.mutate({
                    mutation: CREATE_SITE_STATUS_CSV_REQUEST,
                    variables: {
                        emails: emails
                    }
                });
                this.$q.notify('Email sent');
                this.resetDialog();
            } catch (error) {
                console.error('Error creating filtered site status csv request:', error);
                this.$q.notify({
                    color: 'negative',
                    message: 'Failed to send filtered site status export request'
                });
            }
        }
    }
});
