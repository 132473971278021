export const emailSites = [
    {
        name: 'Guerilla Mail',
        url: 'https://www.guerrillamail.com/',
    },
    {
        name: 'Nada',
        url: 'https://getnada.com/',
    },
    {
        name: 'Throw Away Mail',
        url: 'https://www.throwawaymail.com/en',
    },
    {
        name: 'Email On Deck',
        url: 'http://www.emailondeck.com/',
    },
    {
        name: 'Temp Mail Address',
        url: 'https://www.tempmailaddress.com/',
    },
    {
        name: '10 Minute Mail',
        url: 'https://10minutemail.com/',
    },
    {
        name: 'fastmail',
        url: 'https://app.fastmail.com/',
    }
]
