import { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import date from 'quasar/src/utils/date/date.js';
import { defineComponent } from 'vue'

const queueMutation = gql`
  mutation queue($enqueueNew: Boolean!) {
    queue(enqueueNew: $enqueueNew) {
      id
      firstName
      lastName
      email
      status
      ClientType
      scannedAt
      movedToMonitoringAt
      siteStatusUpdatedAt
      createdAt
    }
  }
`

const queueStatQuery = gql`
  query queueStat {
    queueStat {
      inProgress
      waiting
    }
  }
`

export default defineComponent({
    name: 'QueuePage',

    data() {
        return {
            isUser: false,
            isAdmin: false,
            queryError: null as ApolloError | null,
            clients: [] as any[],
            loading: false,
            table: {
                columns: [
                    { name: 'firstName', required: true, label: 'First Name', align: 'left' as const, field: 'firstName', sortable: true },
                    { name: 'lastName', required: true, label: 'Last Name', align: 'left' as const, field: 'lastName', sortable: true },
                    { name: 'email', label: 'Email', align: 'left' as const, field: 'email', sortable: true },
                    { name: 'status', label: 'Status', align: 'left' as const, field: 'status', sortable: true },
                    { name: 'ClientType', label: 'Client Type', align: 'left' as const, field: 'ClientType', sortable: true },
                    { name: 'daysSinceUpdated', label: 'Days in Current Status', align: 'left' as const, field: 'daysSinceUpdated', sortable: true },
                    { name: 'daysSinceSiteUpdated', label: 'Days Since Site Updated', align: 'left' as const, field: 'daysSinceSiteUpdated', sortable: true },
                ],
            },
            queueStats: {
                inProgress: 0,
                waiting: 0
            }
        }
    },

    mounted() {
        this.fetchQueueStats()
        this.fetchClients(false);
        this.handleClientIdParam();
    },

    methods: {
        async releaseClient(clientId: string) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`
                mutation ReturnClientToQueue($clientId: String!) {
                    returnClientToQueue(clientId: $clientId)
                }
            `,
                    variables: {
                        clientId
                    },
                    refetchQueries: [
                        { query: queueStatQuery }
                    ],
                    awaitRefetchQueries: true
                });
                this.$q.notify('Client returned to queue')

                // Refresh the queue without adding a new client
                await this.fetchClients(false);

                // Update queue stats
                const { data: statsData } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (statsData) {
                    this.queueStats = statsData.queueStat
                }
            } catch (error) {
                console.error('Error releasing client:', error);
            }
        },

        async fetchQueueStats() {
            try {
                const { data } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (data) {
                    this.queueStats = data.queueStat
                }
            } catch (error) {
                console.error("Failed to fetch queue stats: " + error)
            }
        },

        openClient(id: string) {
            this.$router.push(`/client/${id}?fromqueue=true`);
        },

        handleClientIdParam() {
            const clientId = this.$route.query.clientId as string;
            if (clientId) {
                this.releaseClient(clientId);
            }
        },

        async fetchClients(enqueueNew: boolean) {
            this.loading = true
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: queueMutation,
                    variables: { enqueueNew },
                    refetchQueries: [
                        { query: queueStatQuery }
                    ],
                    awaitRefetchQueries: true
                })

                console.log("Complete response from queue mutation:", JSON.stringify(data));

                if (data && data.queue) {
                    // Process clients with the included date information directly
                    this.clients = data.queue.map((item: any) => {
                        console.log("Processing client item:", JSON.stringify(item));

                        // Calculate days since updated directly from the data we receive
                        const calculateDaysDiff = (dateStr: string | null) => {
                            if (!dateStr) return null;
                            try {
                                // Handle the date as a string
                                return date.getDateDiff(new Date(), new Date(dateStr), 'days');
                            } catch (e) {
                                console.error("Error calculating days:", e);
                                return null;
                            }
                        };

                        // Find the latest update date
                        let latestUpdateDate = item.createdAt;

                        if (item.scannedAt && new Date(item.scannedAt) > new Date(latestUpdateDate)) {
                            latestUpdateDate = item.scannedAt;
                        }

                        if (item.movedToMonitoringAt &&
                            new Date(item.movedToMonitoringAt) > new Date(latestUpdateDate)) {
                            latestUpdateDate = item.movedToMonitoringAt;
                        }

                        return {
                            id: item.id || '',
                            firstName: item.firstName || '',
                            lastName: item.lastName || '',
                            email: item.email || '',
                            status: item.status || '',
                            ClientType: item.ClientType || '',
                            daysSinceUpdated: calculateDaysDiff(latestUpdateDate),
                            daysSinceSiteUpdated: calculateDaysDiff(item.siteStatusUpdatedAt),
                            // Keep the original date strings for reference if needed
                            scannedAt: item.scannedAt,
                            siteStatusUpdatedAt: item.siteStatusUpdatedAt,
                            movedToMonitoringAt: item.movedToMonitoringAt,
                            createdAt: item.createdAt
                        };
                    }).filter(Boolean);
                }

                // Fetch the updated queue stats
                const { data: statsData } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (statsData) {
                    this.queueStats = statsData.queueStat
                }

                this.queryError = null
            } catch (error) {
                console.error("Failed to fetch clients: " + error)
                this.queryError = error as ApolloError;
            } finally {
                this.loading = false
            }
        },
        async checkoutClient() {
            try {
                await this.fetchClients(true)
            } catch (error) {
                console.error("Failed to checkout client: " + error)
            }
        },

        getColStyles(name: string, data: any) {
            if (name === 'daysSinceUpdated' && typeof data.daysSinceUpdated === 'number') {
                const baseStyles = {
                    'padding-top': '4px',
                    'padding-bottom': '4px',
                    'padding-right': '8px',
                    'padding-left': '8px',
                    'border-radius': '4px',
                }

                if (data.daysSinceUpdated < 9 || (data.status === 'MONITORING' && data.daysSinceUpdated < 80)) {
                    return {
                        background: '#c0f5bb',
                        color: '#0e5a19',
                        ...baseStyles,
                    }
                } else if (data.daysSinceUpdated < 14 || (data.status === 'MONITORING' && data.daysSinceUpdated < 90)) {
                    return {
                        background: '#ffd08a',
                        color: '#845000',
                        ...baseStyles,
                    }
                } else {
                    return {
                        background: '#fdbfbf',
                        color: '#a20808',
                        ...baseStyles,
                    }
                }
            } else if (name === 'daysSinceSiteUpdated' && typeof data.daysSinceSiteUpdated === 'number') {
                const baseStyles = {
                    'padding-top': '4px',
                    'padding-bottom': '4px',
                    'padding-right': '8px',
                    'padding-left': '8px',
                    'border-radius': '4px',
                }

                if (data.daysSinceSiteUpdated <= 9) {
                    return {
                        background: '#c0f5bb',
                        color: '#0e5a19',
                        ...baseStyles,
                    }
                } else if (data.daysSinceSiteUpdated <= 13) {
                    return {
                        background: '#ffd08a',
                        color: '#845000',
                        ...baseStyles,
                    }
                } else {
                    return {
                        background: '#fdbfbf',
                        color: '#a20808',
                        ...baseStyles,
                    }
                }
            }
            return {}
        },
    }
})
