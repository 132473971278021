import AssignmentSelector from '@/components/AssignmentSelector/AssignmentSelector.vue'
import ClientEditor from '@/components/ClientEditor/ClientEditor.vue'
import { TypeSelections } from '@/components/TypeSelector/TypeSelector'
import TypeSelector from '@/components/TypeSelector/TypeSelector.vue'
import { StatusCounts } from '@/fragments'
import {
    User_authLevel,
    formatForDisplay,
    hasAuthLevel,
    localStoreFilters
} from '@/utils'
import type { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import { date } from 'quasar'
import type { clients_clients } from 'schema-types/clients'
import type { Client_status } from 'schema-types/globalTypes'
import { defineComponent } from 'vue'
import { ClientType as ClientTypes } from './../../../schema-types/globalTypes'

const clientListQuery = gql`
  query clientsUserClientList(
    $page: Int!
    $perPage: Int!
    $search: String
    $assignedTo: String
    $manualChecksOnly: Boolean
    $reAddedOnly: Boolean
    $hideStatuses: [Client_status!]
    $nextScanWithin: String
    $showTypes: [ClientType!]
    $orderBy: [OrderBy!]
    $stripeStatuses: [SubscriptionStatusCode!]
  ) {
    clients(
      page: $page
      perPage: $perPage
      search: $search
      assignedTo: $assignedTo
      manualChecksOnly: $manualChecksOnly
      reAddedOnly: $reAddedOnly
      hideStatuses: $hideStatuses
      nextScanWithin: $nextScanWithin
      showTypes: $showTypes
      orderBy: $orderBy
      stripeStatuses: $stripeStatuses
    ) {
      data {
        id
        firstName
        lastName
        middleName
        email
        status
        updatedAt
        siteStatusUpdatedAt
        nextScan
        movedToMonitoringAt
        scannedAt
        createdAt
        requiresManualCheck
        ClientType
        stripeStatus {
          stripeStatus
        }
        stripeAccount
        assignedTo {
          id
          name
        }
        ...StatusCounts
      }
      totalCount
      totalPages
    }
  }
  ${StatusCounts}
`

export default defineComponent({
    name: 'ClientList',

    data() {
        const filters = localStoreFilters.getItem('clients-filter') as
            | {
                showStatuses: string[]
                showTypes: { label: string; value: string }[]
                sortBy: string
                descending: boolean
                rowsPerPage: number
                stripeStatusFilter: string[]
            }
            | undefined

        return {
            filterInput: '',
            selectedClientIds: [] as string[],
            assignmentSelection: { label: '', value: '' },
            typeSelection: { label: '', value: '' },
            typeSelections: TypeSelections,
            showTutorialDialog: localStorage.getItem('showTutorialDialog'),
            isUser: false,
            isAdmin: false,
            queryError: false as boolean | ApolloError,
            editDialog: false,
            showFiltering: false,
            clientStatuses: ['SELFREMOVAL', 'DRAFT', 'REMOVAL', 'MONITORING', 'ARCHIVED'],
            stripeStatusFilter: filters?.stripeStatusFilter || ['ACTIVE', 'TRIALING', 'PASTDUE', 'CANCELED', 'NOTFOUND'],
            showStatuses: filters?.showStatuses || ['REMOVAL', 'MONITORING'],
            showTypes: filters?.showTypes || TypeSelections,
            clients: [] as clients_clients['data'],
            nextScanWithin: { label: '1 week', value: '1w' },
            nextScanWithinOptions: [
                { label: '1 week', value: '1w' },
                { label: '2 weeks', value: '2w' },
                { label: '4 weeks', value: '4w' },
            ],
            requiredChecksOnly: false,
            appDisplayName: process.env.VUE_APP_DISPLAYNAME,
            table: {
                filterInput: '',
                page: 1,
                totalPages: 1,
                columns: [
                    {
                        name: 'select',
                        required: true,
                        label: '',
                        align: 'left',
                        field: 'select',
                        sortable: false,
                    },
                    {
                        name: 'name',
                        required: true,
                        label: 'Name',
                        align: 'left',
                        field: 'name',
                        sortable: true,
                    },
                    {
                        name: 'email',
                        required: true,
                        align: 'left',
                        label: 'Email',
                        field: 'email',
                        sortable: true,
                    },
                    {
                        name: 'ClientType',
                        required: false,
                        align: 'left',
                        label: 'User Type',
                        field: 'ClientType',
                        sortable: true,
                    },
                    {
                        name: 'stripeAccount',
                        required: false,
                        align: 'left',
                        label: 'Stripe Account',
                        field: 'stripeAccount',
                        sortable: true,
                    },
                    {
                        name: 'stripeStatus',
                        required: false,
                        align: 'left',
                        label: 'Stripe Status',
                        field: 'stripeStatus',
                        sortable: true,
                    },
                    {
                        name: 'status',
                        required: false,
                        align: 'left',
                        label: 'Status',
                        field: 'status',
                        sortable: true,
                    },
                    {
                        name: 'assignedTo',
                        required: false,
                        align: 'left',
                        label: 'Assigned To',
                        field: 'assignedTo',
                        sortable: true,
                    },
                    {
                        name: 'updatedAt',
                        required: false,
                        align: 'left',
                        label: 'Last Updated',
                        field: 'updatedAt',
                        sortable: true,
                    },
                    {
                        name: 'daysSinceUpdated',
                        required: false,
                        align: 'left',
                        label: 'Days in Current Status',
                        field: 'daysSinceUpdated',
                        sortable: true,
                    },
                    {
                        name: 'daysSinceSiteUpdated',
                        required: false,
                        align: 'left',
                        label: 'Days Since Site Updated',
                        field: 'daysSinceSiteUpdated',
                        sortable: true,
                    }
                ],
                pagination: {
                    sortBy: filters?.sortBy || 'name',
                    descending: filters?.descending,
                    rowsPerPage: filters?.rowsPerPage || 50,
                },
            },
        }
    },

    apollo: {
        clients: {
            query: clientListQuery,
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            variables() {
                const filters = localStoreFilters.getItem('clients-filter') as
                    | {
                        showStatuses: string[]
                        showTypes: { label: string; value: string }[]
                        sortBy: string
                        descending: boolean
                        rowsPerPage: number
                        stripeStatusFilter: string[]
                    }
                    | undefined

                return {
                    assignedTo: this.userId,
                    manualChecksOnly: this.manualChecksOnly,
                    reAddedOnly: this.reAddedOnly,
                    hideStatuses: ['DRAFT', 'SELFREMOVAL', 'REMOVAL', 'MONITORING', 'ARCHIVED'].filter(
                        (status) => !(this as any).showStatuses.includes(status),
                    ),
                    showTypes: this.showTypes.map((type) => type.value),
                    stripeStatuses: this.stripeStatusFilter,
                    nextScanWithin: this.upcomingScans ? (this as any).nextScanWithin.value : undefined,
                    page: this.table.page,
                    perPage: this.table.pagination.rowsPerPage || 1000 * 1000,
                    search: this.table.filterInput.trimStart(),
                    orderBy: [
                        {
                            field: this.table.pagination.sortBy || filters?.sortBy,
                            direction:
                                this.table.pagination.sortBy === 'status' ||
                                    this.table.pagination.sortBy === 'daysSinceUpdated' ||
                                    this.table.pagination.sortBy === 'daysSinceSiteUpdated'
                                    ? this.table.pagination.descending
                                        ? 'asc'
                                        : 'desc'
                                    : !this.table.pagination.descending
                                        ? 'asc'
                                        : 'desc',
                        },
                    ],
                }
            },
            result({ data }) {
                if (data) {
                    this.queryError = false

                    this.table.totalPages = data.clients.totalPages

                    this.clients = data.clients.data?.map((item: any) => {
                        delete item.statusCounts.__typename

                        return {
                            name: `${item.firstName} ${item.middleName || ''} ${item.lastName}`,
                            email: item.email,
                            id: item.id,
                            ClientType: TypeSelections.find((x) => x.value === item.ClientType)?.label,
                            status: item.status,
                            updatedAt: formatForDisplay(item.updatedAt, 'updatedAt'),
                            siteStatusUpdatedAt: formatForDisplay(
                                item.siteStatusUpdatedAt,
                                'siteStatusUpdatedAt',
                            ),
                            daysSinceUpdated: date.getDateDiff(new Date(),
                                item.scannedAt && item.movedToMonitoringAt
                                    ? new Date(Math.max(new Date(item.scannedAt).getTime(), new Date(item.movedToMonitoringAt).getTime()))
                                    : item.scannedAt || item.movedToMonitoringAt || item.createdAt,
                                'days'),
                            daysSinceSiteUpdated: date.getDateDiff(new Date(), item.siteStatusUpdatedAt, 'days'),
                            nextScan: item.nextScan,
                            requiresManualCheck: item.requiresManualCheck,
                            scannedAt: item.scannedAt,
                            assignedTo: item.assignedTo?.name || 'No Assignment',
                            assignedToId: item.assignedTo?.name,
                            ...item.statusCounts,
                            stripeStatus: item.stripeStatus?.stripeStatus || 'NOTFOUND',
                            stripeAccount: item.stripeAccount || item.email,
                            selected: false,
                        }
                    })
                } else {
                    this.clients = []
                }
            },
            error(err) {
                this.queryError = err
            },
        },
    },

    mounted() {
        hasAuthLevel(this.$apollo, User_authLevel.ADMIN).then((isAdmin) => {
            this.isAdmin = isAdmin
        })

        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })

        if (this.userId) {
            this.showStatuses = ['DRAFT', 'SELFREMOVAL', 'REMOVAL', 'MONITORING', 'ARCHIVED']
        }
    },

    computed: {
        selectedClients(): clients_clients['data'] {
            return this.clients.filter(client => this.selectedClientIds.includes(client.id))
        },

        visibleColumns() {
            const visibleColumns: string[] = ['name', 'email']
            if (this.isUser) {
                if (!this.userId) {
                    visibleColumns.push('assignedTo')
                }
                visibleColumns.push('stripeAccount', 'status', 'stripeStatus', 'updatedAt', 'daysSinceUpdated', 'daysSinceSiteUpdated')
            }
            return visibleColumns
        },

        tableName(): string {
            if (this.isUser) {
                return `Clients${this.manualChecksOnly
                    ? ' That Require Manual Checks'
                    : this.reAddedOnly
                        ? ' That Have Been Re Added To A Site'
                        : this.upcomingScans
                            ? ' With Upcoming Scans'
                            : ''
                    }`
            } else {
                return 'Your Family'
            }
        },
    },

    watch: {
        showStatuses: {
            handler() {
                this.saveFilters()
            },
        },
        showTypes: {
            handler() {
                this.saveFilters()
            },
        },
        stripeStatusFilter: {
            handler() {
                this.saveFilters()
            },
        },
        'table.pagination': {
            handler() {
                this.saveFilters()
            },
        },
        'table.filterInput': {
            handler() {
                this.$apollo.queries.clients.refetch()
            },
        },
    },

    methods: {
        confirmReleaseAllClients() {
            this.$q.dialog({
                title: 'Confirm Release All',
                message: 'Are you sure you want to release all clients?',
                cancel: true,
                persistent: true
            }).onOk(() => {
                this.releaseAllClients();
            });
        },

        async releaseAllClients() {
            this.$q.notify('Releasing all clients. Please wait.');
            try {
                await this.$apollo.mutate({
                    mutation: gql`
        mutation UnassignFromAllClients {
          unassignFromAllClients
        }
      `,
                    refetchQueries: [clientListQuery],
                });
            } catch (error) {
                console.error('Failed to release all clients', error);
                this.$q.notify({
                    color: 'negative',
                    message: 'Failed to release all clients'
                });
            }
        },

        async releaseSelectedClients() {
            this.$q.notify('Releasing selected clients...')
            for (const clientId of this.selectedClientIds) {
                try {
                    await this.$apollo.mutate({
                        mutation: gql`
                        mutation updateClientAssignmentUserClientList($id: String!, $assignTo: String) {
                            updateClient(id: $id, assignTo: $assignTo) {
                                id
                                assignedTo {
                                    id
                                    name
                                }
                            }
                        }
                    `,
                        variables: {
                            id: clientId,
                            assignTo: '',
                        },
                        refetchQueries: [clientListQuery],
                    });
                    console.log(`Released client with ID: ${clientId}`);
                } catch (error) {
                    console.error(`Failed to release client with ID: ${clientId}`, error);
                }
            }
        },

        openInNewTab(clientId: string) {
            const url = `${window.location.origin}/client/${clientId}`;
            window.open(url, '_blank');
        },

        getStripeStatusColor(status: string) {
            switch (status) {
                case 'ACTIVE':
                    return 'green'
                case 'TRIALING':
                    return 'light-blue'
                case 'PASTDUE':
                    return 'orange'
                case 'CANCELED':
                    return 'red'
                default:
                    return 'grey'
            }
        },

        saveFilters() {
            const filters = localStoreFilters.getItem('clients-filter') as
                | {
                    showStatuses: string[]
                    showTypes: { label: string; value: string }[]
                    sortBy: string
                    descending: boolean
                    rowsPerPage: number
                    stripeStatusFilter: string[]
                }
                | undefined

            localStoreFilters.setItem('clients-filter', {
                showStatuses: this.showStatuses,
                showTypes: this.showTypes,
                sortBy: this.table.pagination.sortBy || filters?.sortBy,
                descending: this.table.pagination.descending,
                rowsPerPage: this.table.pagination.rowsPerPage,
                stripeStatusFilter: this.stripeStatusFilter,
            })


        }, // apply if needed: this.$apollo.queries.clients.refetch()

        hideTutorialDialog() {
            this.showTutorialDialog = null

            localStorage.removeItem('showTutorialDialog')
        },

        formatForDisplay(data: any, name: string) {
            if (name === 'status' && typeof data === 'string') {
                return data.toLowerCase().replace(/^./, (t) => t.toUpperCase())
            }
            return formatForDisplay(data, name)
        },

        getColStyles(
            name: string,
            {
                requiresManualCheck,
                scannedAt,
                daysSinceUpdated,
                daysSinceSiteUpdated,
                status,
                ClientType,
                stripeStatus,
                assignedToId
            }: {
                requiresManualCheck: boolean
                scannedAt: string
                daysSinceUpdated: number | string
                daysSinceSiteUpdated: number | string
                status: Client_status
                ClientType: ClientTypes
                stripeStatus: string
                assignedToId: string
            },
        ) {
            if (name === 'scannedAt' && scannedAt) {
                if (requiresManualCheck) {
                    return {
                        background: '#fdbfbf',
                        color: '#a20808',
                        padding: '4px',
                        'border-radius': '4px',
                    }
                } else {
                    return {
                        background: '#c0f5bb',
                        color: '#0e5a19',
                        padding: '4px',
                        'border-radius': '4px',
                    }
                }
            } else if (name === 'name') {
                const baseStyles = {
                    'padding-top': '4px',
                    'padding-bottom': '4px',
                    'padding-right': '8px',
                    'padding-left': '8px',
                    'border-radius': '4px',
                }

                const clientTypeValue = TypeSelections.find((x) => x.label === ClientType)?.value

                switch (clientTypeValue) {
                    case ClientTypes.NONUS:
                        return {
                            background: 'rgb(37 99 235)',
                            color: 'white',
                            ...baseStyles,
                        }

                    case ClientTypes.SPECIAL:
                        return {
                            background: '#fdbfbf',
                            color: '#a20808',
                            ...baseStyles,
                        }

                    case ClientTypes.EMPLOYEE:
                        return {
                            background: '#c0f5bb',
                            color: '#0e5a19',
                            ...baseStyles,
                        }

                    case ClientTypes.TEST:
                        return {
                            background: 'rgb(254 240 138)',
                            color: 'black',
                            ...baseStyles,
                        }

                    case ClientTypes.GROUP:
                        return {
                            background: '#ffd08a',
                            color: '#845000',
                            ...baseStyles,
                        }
                    default:
                        break
                }
            } else if (name === 'stripeStatus') {
                const baseStyles = {
                    'padding-top': '4px',
                    'padding-bottom': '4px',
                    'padding-right': '8px',
                    'padding-left': '8px',
                    'border-radius': '4px',
                }

                if (!this.isAdmin) return baseStyles

                switch (stripeStatus) {
                    case 'ACTIVE':
                        return {
                            background: '#4CAF50',
                            color: 'white',
                            ...baseStyles,
                        }
                    case 'TRIALING':
                        return {
                            background: '#4169E1',
                            color: 'white',
                            ...baseStyles,
                        }
                    case 'PASTDUE':
                        return {
                            background: '#FFA07A',
                            color: 'white',
                            ...baseStyles,
                        }
                    case 'CANCELED':
                        return {
                            background: '#FF6B6B',
                            color: 'white',
                            ...baseStyles,
                        }
                    default:
                        return {
                            background: 'gray',
                            color: 'white',
                            ...baseStyles,
                        }
                }
            } else if (name === 'daysSinceUpdated' && typeof daysSinceUpdated === 'number') {
                if (daysSinceUpdated < 9 || (status === 'MONITORING' && daysSinceUpdated < 80)) {
                    return {
                        background: '#c0f5bb',
                        color: '#0e5a19',
                        'padding-top': '4px',
                        'padding-bottom': '4px',
                        'padding-right': '8px',
                        'padding-left': '8px',
                        'border-radius': '4px',
                    }
                } else if (daysSinceUpdated < 14 || (status === 'MONITORING' && daysSinceUpdated < 90)) {
                    return {
                        background: '#ffd08a',
                        color: '#845000',
                        'padding-top': '4px',
                        'padding-bottom': '4px',
                        'padding-right': '8px',
                        'padding-left': '8px',
                        'border-radius': '4px',
                    }
                } else {
                    return {
                        background: '#fdbfbf',
                        color: '#a20808',
                        'padding-top': '4px',
                        'padding-bottom': '4px',
                        'padding-right': '8px',
                        'padding-left': '8px',
                        'border-radius': '4px',
                    }
                }
            } else if (name === 'daysSinceSiteUpdated' && typeof daysSinceSiteUpdated === 'number') {
                const baseStyles = {
                    'padding-top': '4px',
                    'padding-bottom': '4px',
                    'padding-right': '8px',
                    'padding-left': '8px',
                    'border-radius': '4px',
                }

                if (!assignedToId) {
                    return baseStyles
                }

                if (daysSinceSiteUpdated <= 9) {
                    return {
                        background: '#c0f5bb',
                        color: '#0e5a19',
                        ...baseStyles,
                    }
                } else if (daysSinceSiteUpdated <= 13) {
                    return {
                        background: '#ffd08a',
                        color: '#845000',
                        ...baseStyles,
                    }
                } else {
                    return {
                        background: '#fdbfbf',
                        color: '#a20808',
                        ...baseStyles,
                    }
                }
            }
        },

        filterColumnData(columnData: { [index: string]: string }): {
            [index: string]: string
        } {
            const columnOrder = this.table.columns.map(({ field }) => field)

            const exclude = ['__index', 'id', 'requiresManualCheck', 'assignedToId']
                ;[
                    'selected',
                    'assignedTo',
                    'status',
                    'nextScan',
                    'scannedAt',
                    'updatedAt',
                    'daysSinceUpdated',
                    'daysSinceSiteUpdated',
                    'ClientType',
                    'siteStatusUpdatedAt',
                    'nextScan',
                    'scannedAt',
                    'unknown',
                    'noExist',
                    'exist',
                    'removalRequested',
                    'removed',
                ].forEach((col) => {
                    if (!this.visibleColumns.includes(col)) {
                        exclude.push(col)
                    }
                })

            return Object.keys(columnData)
                .filter((key) => !exclude.includes(key))
                .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
                .reduce((obj: { [index: string]: string }, key) => {
                    obj[key] = columnData[key]
                    return obj
                }, {})
        },
    },

    components: {
        ClientEditor,

        AssignmentSelector,

        TypeSelector,
    },

    props: {
        userId: String,
        manualChecksOnly: Boolean,
        reAddedOnly: Boolean,
        upcomingScans: Boolean,
    },
})
